<template>
    <div class="self-extract-container">
        <div class="doneOrderList">
            <date-ranger-v1 @chooseDate="chooseDate"></date-ranger-v1>
        </div>
        <scrollview @onRefresh="onRefresh" @onReload="onReload" :height="scrollHeight">
            <div class="excelList">
                <div class="row-flex-between line-box" v-for="(item, index) in list" :key="index">
                    <div class="label" style="width: 80%">{{item.id}}.{{item.title}}</div>
                    <!-- <div class="label" style="width: 30%">{{item.belong_to_date}}</div>-->
                    <div class="label"><a target="_blank" :href="item.url"  style="color:red">下载</a> </div>
                </div>

            </div>
        </scrollview>
    </div>

</template>

<script>
    import SearchV2 from "../../template/community/search/searchV2";
    import DateRangerV1 from "../../template/community/date/dateRangerV1";

    export default {
        name: 'statistics-points-download',
        components: {SearchV2,DateRangerV1},

        data () {
            return {
                date:'2022-12-12',
                dataInfo: {
                    user_info: {},

                },
                params: {
                    page: 1,
                    page_size: 15,
                    date:''
                },
                list: [],
                scrollHeight: 0,

            }
        },
        created () {
            //this.myCenterInfo()
            this.statisticsPointsDownload(this.params)

        },

        mounted() {
            this.scrollHeight = window.innerHeight - document.querySelector('.doneOrderList').clientHeight
        },


        methods: {
            chooseDate(date) {
                this.list = [];
                this.params.page = 1;
                this.params.date = date.toString();
                this.statisticsPointsDownload(this.params).finally(() => {
                    //done();
                });

            },
            search () {

            },
            //获excel报表
            async statisticsPointsDownload(params) {

                try {
                    let query = await this.$api.community.points.statisticsPointsDownload(params)
                    console.log(query)
                    if (query.data.length == 0) {
                        return;
                    }
                    this.list = [...this.list, ...query.data];


                } catch (error) {

                }

            },
            onRefresh(done) {
                this.list = [];
                this.params.page = 1;
                this.statisticsPointsDownload(this.params).finally(() => {
                    done();
                });
            },
            /**
             * step 当前加载结束
             * over 没有更多数据了
             */
            onReload(step, over) {
                console.log("上拉加载");
                this.params.page += 1;
                this.statisticsPointsDownload(this.params).finally(() => {
                    if (this.list.length < this.params.page * this.params.page_size) {
                        over();
                    } else {
                        step();
                    }
                });
            },
        },
    }
</script>

<style scoped lang="scss">
    div{
        border:0px solid red;
    }
    .row-flex-between{
        display: flex;
        flex-direction: row;
        justify-content: space-between;

    }
    .self-extract-container {
        background:#F6F6F6;
        overflow: hidden;
        overflow-y: scroll;
        position: relative;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding-bottom: constant(safe-area-inset-bottom);
        padding-bottom: env(safe-area-inset-bottom);
        padding:10px;
        .date-ranger-container{
            width: 100%;
            height: 100px;
            border: 1px solid red;
        }
        .label{
            font-size: 16px;
            color: #333333;
            padding: 10px 0;
        }
    }
    .line-box{
        border: 0px solid red;
        line-height: 20px;
        padding: 5px 10px;
        margin-top: 10px;
        background-color: #FFFFFF;
        border-radius: 5px;
    }



</style>
